@import '../../../sass/abstracts/mixins';
@import '../../../sass/abstracts/variables';

.section-integracao-body {

  @media (max-width: $max-width-mobile) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  &__web {
    display: flex;
    justify-content: space-between;
    width: 880px;

    @media (max-width: 900px) {
      justify-content: center;
      margin: 0 auto;
    }

    @media (max-width: $max-width-mobile) {
      display: none;
    }
  }
}

.section-integracao-sistema {
  display: flex;
  justify-content: center;
  padding: 171px 0;
  @media (max-width: $max-width-mobile) {
    justify-content: center;
    align-items: center;
    height: 920px;
    padding: 0 24px;
    box-sizing: border-box;
    margin: 100px 0px;
  }

  &__primary-text {
    @include sectionPrimaryText;
    
    &__mobile {
      display: none;
      @media (max-width: $max-width-mobile) {
        font-weight: 600;
        font-size: 26px;
        line-height: 130%;
        display: block;
      }
    }
  }

  &__secondary-text {
    @include sectionSecondaryText;
    
    &__mobile {
      @include sectionSecondaryText;
      display: none;
      @media (max-width: $max-width-mobile) {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 12px 0px;
        display: block;
      }
    }    
  }

  &__text {
    width: 340px;

    @media (max-width: 900px) {
      margin: 0px 0px 45px 0px;
    }

    @media(max-width: $max-width-mobile) {
      width: 100%;
    }
  }
  
  &-img {
    width: 431px;
    height: 355px;
    
    &__mobile {
      display: none;
      @media (max-width: $max-width-mobile) {
        width: 327px;
        height: 270px;
        display: block;
      }
    }
  }
  
  &__icons {
    display: flex;
    
    &-default {
      width: 19px;
      height: 16px;
      margin: 0 3px;
    }

    &-angle {
      width: 7px;
      height: 14px;
      margin: 0 3px;
    }
    
    &__mobile {
      display: none;
      @media (max-width: $max-width-mobile) {
        width: 100%;
        display: block;
      }
    }
  }
  
  a {
    margin-left: 5px;
    color: $color-hard-gray ;
  }
}
