@import '../../../sass/abstracts/variables';


.container-banner{
  width: 100%;
  height: 243px;
  margin-top: 60px;
  background: url('../../../assets/img/banner-fale-conosco.png') no-repeat;
  background-size: cover;

  @media (max-width: $max-width-mobile) {
    background: url('../../../assets/img/banner-fale-conosco-small.png') no-repeat;
    background-size: cover;
    height: 72px;
  }
}
