.button {
  font-size: 16px;
  font-weight: 600;
  color: $color-white;
  animation: background .2s;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding: 11px 30px;

  display: inline-block;

  &--primary-tint {
    background: $color-primary-tint;
    box-shadow: 0px 4px 8px rgba(70, 118, 250, 0.5);
    border: 1px solid $color-primary-tint;

    &:hover {
      background: $color-primary-tint-hover;
      box-shadow: 0px 4px 8px rgba(70, 118, 250, 0.5);
    }
  }

  &--primary {
    background: $color-primary;
    box-shadow: 0px 4px 8px rgba(32, 71, 174, 0.5);
    border-radius: 4px;
    border: 1px solid $color-primary;

    &:hover {
      background: $color-primary-hover;
      box-shadow: 0px 4px 8px rgba(32, 71, 174, 0.5);
    }
  }

  &--rounded {
    border-radius: 100px;
    padding: 8px 26px;
  }

  &:disabled {
    background: $color-disabled;
    border: 1px solid $color-disabled;
    box-shadow: none;
  }

  &--personal{
    width: 94px;
    height: 32px;
    font-size: 14px;
    padding: 6px 26px;
  }
}
