.form-group {
  label {
    display: block;
    font-size: 16px;
    font-weight: 500;

    margin-bottom: 8px;
  }

  input {
    width: 100%;
    background: #F6F8FC;
    border-radius: 4px;
    height: 40px;
    font-size: 14px;
    color: #2F3139;

    border: none;
    outline: none;

    padding-left: 15px;

    &:focus {
      border: 1.5px solid #2047AE;
    }
  }

  margin: 35px 0px;
}