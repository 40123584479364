.header {
  background-image: url('../../assets/img/imagem-topo-site.jpg');
  background-size: cover;

  position: relative;

  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

  width: 100%;
  height: -webkit-fill-available;

  margin-top: -50px;
  z-index: 0;

  @media (max-width: $max-width-medium) {
    background-image: url('../../assets/img/imagem-topo-site-mobile.png');
    margin-top: 0;
  }

  &__text-box {
    width: 525px;
    z-index: 0;

    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);

    @media (max-width: $max-width-medium) {
      width: 330px;
      margin-top: 0;

      left: 24px;
      bottom: 30px;
      top: auto;
      transform: translateX(0);
    }

    @media (max-width: $max-width-mobile-sm) {
      width: 300px;
      margin-top: 0;

      left: 50%;
      bottom: 30px;
      top: auto;
      transform: translateX(-50%);
    }
  }

  &__heading-primary {
    font-size: 60px;
    font-weight: 700;
    color: $color-white;

    line-height: 1.2;
    margin: 20px 0px;

    @media (max-width: $max-width-medium) {
      font-size: 38px;
    }
  }

  &__heading-secondary {
    font-size: 16px;
    font-weight: 400;
    color: $color-white;

    margin-bottom: 25px;
  }

  &__app-links {
    @media (max-width: $max-width-mobile) {
      display: flex;
      justify-content: space-between;
    }

    .link {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 18px;
      }

      @media (max-width: $max-width-mobile) {
        margin: 0;
      }

      img {
        @media (max-width: $max-width-mobile-sm) {
          width: 100px;
        }
      }
    }
  }
}
