.animated-list {
  list-style: none;
  
  li {
    position: relative;
    color: $color-hard-gray;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    
    padding-left: 27px;
    margin-bottom: 56px;

    cursor: pointer;
    
    &.active {
      color: $color-primary-tint;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400px;
      
      &::before {
        width: 8px;
        height: 8px;
        background: $color-primary-tint;
      }
    }
    
    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      margin-right: 20px;
      
      content: '';
      width: 4px;
      height: 4px;
      background: $color-black;
      border-radius: 50%;
    }
  }
}