@import '../../../sass/abstracts/variables';
@import '../../../sass/abstracts/mixins';

$margin-span: 13px;

.conduta-interativa{
  @include sectionSetup();
  
  @media (min-width: $max-width-mobile) {
    position: relative;     
    gap: 30px;
  }
  @media (max-width: $max-width-mobile) {
    flex-direction: column;
    margin: 0 auto;    
    align-items: flex-start;
    padding: 0px 24px;  
  }

  &__info{
    display: flex;
    flex-direction: column;
    width: 350px;  

    @media (max-width: $max-width-mobile) {
     width: 100%;     
    }

    h3{
      @include sectionPrimaryText();
      margin-bottom:15px;
      @media (max-width: $max-width-mobile) {
        font-weight: 600;
        font-size: 26px;
        line-height: 130%;
        margin: 12px 0px;
        display: block;
      }
    }
    span{
      @include sectionSecondaryText();
      margin-bottom: $margin-span;
      margin-top: $margin-span;
    }
  }

  &__img{
    @media (min-width: $max-width-mobile) {
      width: 431px;
      height: 391px;        
    }
    @media (max-width: $max-width-mobile) {            
      display: none;      
    }
  }
  &__img-mobile{
    display: none;
    @media (max-width: $max-width-mobile) {
      display: block;
      width: 100%;
      height: auto;      
      margin-top: 20px;
    }
  }

}
