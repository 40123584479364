@keyframes openMobileMenuBody {
  from {
    height: 0;
  }

  to {
    height: calc(100vh - 60px);
  }
}

@keyframes closeMobileMenuBody {
  from {
    height: calc(100vh - 60px);
  }

  to {
    height: 0;
  }
}

.navbar {
  width: 100%;
  height: 60px;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid $color-light-gray;

  background-color: $color-white;

  @media (max-width: $max-width-medium) {
    padding: 15px 24px;
  }
  
  &__body {    
    height: 100%;
    width: 1054px;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1080px) {
      width: 100%;
      padding: 15px 20px;
    }
    @media (min-width: $max-width-mobile){
      position: relative;
    }
    @media (max-width: $max-width-mobile) {
       width: 100%;
       padding: 0;
    }
  }

  &__body-b2c {    
    height: 100%;
    width: 100%;
    padding: 15px 112px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1080px) {
      width: 100%;
      padding: 0px 20px;
    }

    @media (min-width: $max-width-mobile){
      position: relative;
      padding: 15px 20px;
    }
    
    @media (max-width: $max-width-mobile) {
      width: 100%;
      padding: 0;
    }
  }

  &__menu {
    width: 100%;
    display: flex;
    align-items: center;
    list-style-type: none;
    
    li {
      margin-left: 40px;
      
      &.active {
        border-bottom: 2px $color-black solid;
      }
      
    }
    

    @media (max-width: $max-width-medium) {
      display: none;
    }

    &-left {
      justify-content: flex-end;

      a {
        margin-left: 40px;
      }
    }

    &-mobile {
      display: none;

      @media (max-width: $max-width-medium) {
        display: inline-block;
      }

      &__body {
        width: 100%;
        overflow: hidden;
        height: 0;

        &.show {
          animation-name: openMobileMenuBody;
          animation-duration: 0.35s;
          animation-fill-mode: forwards;

          padding: 32px 28px 41px 24px;
        }

        &.close {
          animation-name: closeMobileMenuBody;
          animation-duration: 0.35s;
          animation-fill-mode: forwards;

          padding: 35px 25px;
        }

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        position: absolute;
        top: 60px;
        left: 0;

        z-index: 1;
        background: $color-white;
        padding: 0;

        &__header {
          width: 100%;
          opacity: 0;

          h1 {
            font-size: 26px;
            color: $color-black-darker;
            font-weight: 600;

            margin-bottom: 32px;
          }

          &__links {
            display: flex;
            justify-content: space-between;
          }

          .select__navbar {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            width: 100%;
            
            background: none;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;

            font-size: 14px;
            font-weight: 500;
            text-decoration: none;

            img {
              width: 15px;
              height: 15px;
              transition: 0.3s;
            }
          }
        }

        &__footer {
          opacity: 0;
          width: 100%;
        }

        &__products {
          padding: 24px 0 24px 24px;
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: space-around;
          align-items: flex-start;
          
          a {
            padding-bottom: 24px;
          }
          
          h3 {
            padding-bottom: 5px;
          }
          
          &-primary-text {
            font-style: normal;
            font-size: 20px;
            font-weight: 600;
            line-height: 22px;
          }

          &-secondary-text {
            color: $color-hard-gray;
            font-style: normal;
            font-size: 16px;
            font-weight: 500;
            line-height: 17px;
          }
          
          &-button-enterprise {
            background: none;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
          }
        }
      }
    }

    .select__navbar {
      background: none;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;

      font-size: 14px;
      font-weight: 500;
      text-decoration: none;

      &:hover {
        color: $color-primary-tint;
      }

      img {
        margin: 0 0 1px 3px;
        transition: 0.4s;
      }
    }
  }
}
