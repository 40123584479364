@import '../../../sass/abstracts/variables';


.container-principais-vantagens{
  width: 100%;
  height: 642px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $max-width-mobile) {
    justify-content: center;
    align-items: center;
    height: 920px;
    padding: 0 24px;
    box-sizing: border-box;
    margin: 100px 0px;
  }

  .title-principais-vantagens{
    width: 443px;
    height: 68px;

    @media (max-width: $max-width-mobile) {
      flex-direction: column;
      width: 100%;
      height: 102px;
    }

    h1{
      color: $color-black;
      font-size: 26px;
      text-align: center;
      font-weight: 600;

      @media (max-width: $max-width-mobile) {
        text-align: left;
      }
    }
  }

  .box-cards-principais-vantagens{
    width: 876px;
    height: 356px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;

    @media (max-width: $max-width-mobile) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 816px;
      margin: 0;
    }

    .card-principais-vantagens{
      width: 276px;
      height: 256px;
      padding: 25px 24px;
      border: 1.5px solid $color-light-gray;
      box-sizing: border-box;
      border-radius: 10px;
      background-color: $color-white;

      @media (max-width: $max-width-mobile) {
        width: 100%;
        margin-top: 24px;
      }

      img{
        width: 24px;
        height: 24px;
      }


      h1{
        font-size: 20px;
        color: $color-primary-tint;
        font-weight: 600;
        margin-top: 16.45px;
        line-height: 110%;
      }

      p{
        font-size: 14px;
        color: $color-hard-gray;
        margin-top: 16px;
      }
    }
  }
}
