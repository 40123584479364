.list {
  width: 100%;
  list-style: none;

  &__item {
    position: relative;
    font-size: 14px;
    line-height: 21px;

    padding-left: 16px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
    
    &::before {
      position: absolute;
      content: '';
      
      left: 0;
      top: 5px;
      
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $color-primary;
    }
  }
}