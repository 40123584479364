.p0 {
  padding: 0 !important;
}

.mb0{
  margin-bottom: 0 !important;
}

.pb24{
  padding-bottom: 24px !important;
}

.pb8{
  padding-bottom: 8px !important;
}