@import '../../../sass/abstracts/mixins';
@import '../../../sass/abstracts/variables';

.section-diferenciais{
  @include sectionSetup();
  margin-top: 200px;
  display: flex;
  flex-direction: column;

  &__title{
    @include sectionPrimaryText();
  }

  .cards-diferenciais{
    display: flex;
    flex-direction: row;

    margin-top: 20px;
    gap: 20px;

    @media (max-width: $max-width-mobile-sm) {
      flex-direction: column;
      margin-top: 0px;
      gap: 0px;
    }
  }
}
