.section-header {
  p {
    display: inline-block;
    font-size: 14px;
    color: $color-primary;
    background-color: $color-primary-tint-background;
    border-radius: 4px;
    padding: 1px 8px;
  }
  &__mobile {
    display: none;
    @media (max-width: $max-width-mobile) {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      align-self: flex-start;
      color: $color-primary;
      background-color: $color-primary-tint-background;
      border-radius: 4px;
      padding: 1px 8px;
      margin-bottom: 5px;
      display: block;
    }
  }
}
