.saiba-mais {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: $color-primary-tint;
  cursor: pointer;
  text-decoration: none;
  span {
    padding-right: 8px;
  }

  &__mobile {
    display: none;
    @extend .saiba-mais;
    @media (max-width: $max-width-mobile) {
      display: block;
      align-self: flex-start;
    }
    &:hover, &:active {
      text-decoration: none !important;
    }
  }
}

.saiba-mais:hover {
  text-decoration: underline $color-primary-tint;
}

.saiba-mais::after {
  content: url('../../assets/img/arrow.svg');
}
