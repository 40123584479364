@mixin sectionSetup {
  width: 100%;

  padding-bottom: 135px;
  background: $color-white;

  display: flex;
  justify-content: center;
  align-items: center;

  @media(max-width: $max-width-mobile) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@mixin sectionPrimaryText {
  color: $color-black-darker;
  font-size: $section-primary-text-size;
  font-weight: 600;
}

@mixin sectionSecondaryText {
  color: $color-hard-gray;
  font-size: $section-secondary-text-size;
  font-weight: 400;

  margin: 24px 0px;
}