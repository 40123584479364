@import '../../../sass/abstracts/mixins';
@import '../../../sass/abstracts/variables';

.section-demonstracao {
  @include sectionSetup;

  flex-direction: column;
  gap: 32px;

  padding-top: 80px;

  @media (max-width: $max-width-mobile) {
    padding-top: 24px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    margin: 0 auto;
  }
}

.title-board {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.title-board__title {
  max-width: 380px;
  text-align: center;
}

.title-board__button {
  display: flex;
  width: 380px;
  height: 40px;
  padding: 14px 8px;
  justify-content: center;
  align-items: center;
  gap: 20px;

  border-radius: 100px;
  border: 2px solid var(--Black, #2F3139);

  color: var(--Black, #2F3139);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
}

.title-board__img {
  width: 24px;
  height: 24px;
}

.medflow-demonstracao__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  width: 100%;
  max-width: 876px;
}

.medflow-demonstracao__item {
  display: flex;
  width: 100%;
  height: 64px;
  padding: 16px 24px;
  align-items: center;
  gap: 16px;

  border-radius: 8px;
  border: 1px solid var(--Gray-100, #D9DBE3);
  background: #FFF;
  color: var(--Black-Alpha-200, #2F3139);

  cursor: pointer;

  &:hover {
    color: var(--Purple-100, #4676FA);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  }

  &.disabled {
    border: 1px solid var(--Gray-100, #D9DBE3);
    background: var(--Gray-50, #F0F2F5);
    color: var(--Gray-200, #A7A9B1);

    cursor: not-allowed;
    
    &:hover {
      color: var(--Gray-200, #A7A9B1);
      box-shadow: none;
    }
  }
}

.medflow-demonstracao__item__loading {
  display: flex;
  justify-content: center;

  margin-left: auto;

  svg {
    width: 20px;
    height: 20px;
    animation: rotate 600ms linear infinite;
    display: block;
  }
}

.medflow-demonstracao__item__icon {
  width: 32px;
  height: 32px;
}
.medflow-demonstracao__item__text {
  /* Text 18 */
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}