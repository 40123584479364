.swiper {
  display: none;
  width: 100%;
  height: 150px;
  margin-top: 32px;
  padding-bottom: 24px;
  
  &-slide  {
    font-size: 16px;
    font-weight: 400;
    color: $color-hard-gray;
    text-align: center;

    padding: 0px 24px;
  }
  
  @media (max-width: $max-width-medium)  {
    display: block;
  }
}
