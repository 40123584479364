@import '../../../sass/abstracts/mixins';
@import '../../../sass/abstracts/variables';

.beneficios-img {
  display: block;
  margin: 0 40px;
}

.section-body {
  &__web {
    display: block;

    @media (max-width: 900px) {
      width: 70%;
      justify-content: center;
      margin: 0 auto;
    }

    @media (max-width: $max-width-mobile) {
      display: none;
    }
  }
}

.section-beneficios {
  @include sectionSetup;

  padding-top: 135px;

  @media (max-width: $max-width-mobile) {
    padding-top: 24px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    margin: 0 auto;
  }

  .beneficios-img {
    width: 442px;
    margin-left: 0px;
    margin-right: 89px;
    border-radius: 20px;

    @media (max-width: $max-width-mobile) {
      display: block;
      margin: 12px auto;
      width: 100%;
    }
  }

  &__primary-text {
    margin-top: 8px;
    @include sectionPrimaryText;

    &__mobile {
      display: none;
      @media (max-width: $max-width-mobile) {
        font-weight: 600;
        font-size: 26px;
        line-height: 130%;
        margin: 12px 0px;
        display: block;
      }
    }
  }

  &__secondary-text {
    @include sectionSecondaryText;
    margin: 8px 0px 16px 0px;
    &__mobile {
      @include sectionSecondaryText;
      display: none;
      @media (max-width: $max-width-mobile) {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 12px 0px;
        display: block;
      }
    }
  }
}

.saiba-mais {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-decoration: none;
  color: $color-primary-tint;
  cursor: pointer;
  span {
    padding-right: 8px;
  }
}

.saiba-mais:hover {
  text-decoration: underline $color-primary-tint;
}

.saiba-mais::after {
  content: url('../../../assets/img/arrow.svg');
}
