@import '../../../sass/abstracts/variables';


.container-formularios{
  width: 100%;
  height: 401px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8.75%;
  box-sizing: border-box;
  margin-top: 40px;

  @media (max-width: $max-width-mobile) {
    height: 572.81px;
    flex-direction: column;
    padding: 0 6.4%;
    margin-bottom: 120px;
  }

  .box-texts{
    width: 100%;
    height: 106px;
    margin-bottom: 40px;

    @media (max-width: $max-width-mobile) {
      width: 327px;
      margin-bottom: 0;
    }

    h1{
      font-size: 60PX;
      font-weight: 700;
      color: $color-black;
      @media (max-width: $max-width-mobile) {
        font-size: 26px;
      }
    }

    p{
      font-weight: 400;
      font-size: 16px;
      color: $color-black;

      @media (max-width: $max-width-mobile) {
        font-size: 14px;
      }
    }
  }


  .container-inputs{
    width: 100%;
    height: 255px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $max-width-mobile) {
      width: 327px;
      flex-direction: column;
      justify-content: space-between;
      height: 424px;
    }

    .box-one, .box-two{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: $max-width-mobile) {
        margin-top: 10px;
        width: 327px
      }
      
      .box-input{
        display: flex;
        flex-direction: column;
        
        span, input, textarea{
          font-size: 14px;
          font-weight: 400;
          color: $color-black-darker;
        }
  
        input, textarea{
          width: 100%;
          background-color: $color-gray-secondary;
          border-radius: 4px;
          border: none;
          padding-left: 15px;
          outline: none;
          &:focus {
            border: 1.5px solid $color-primary;
          }
        }
        span{
          margin-bottom: 8px;
        }
  
        input{
          height: 40px;
        }
  
        textarea{
          height: 133px;

          @media (max-width: $max-width-mobile) {
            height: 80px;
          }
        }
  
      }
    }
  
    .box-one{
      margin-right: 16px;
      @media (max-width: $max-width-mobile) {
        margin: 0;
        height: 246px;
      }
    }
  
    .box-two{
      @media (max-width: $max-width-mobile) {
        height: 178px;
      }
      button{
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 600;
        color: $color-white;
        cursor: pointer;
        background-color: $color-primary-tint;
        
                
        &:hover{
          background-color: $color-primary-tint-hover;
        }
        
        &:disabled{
          background-color: $color-disabled;
        }

        &:not(:disabled){
          box-shadow: 0px 4px 8px rgba(32, 71, 174, 0.5);
          border-radius: 4px;
        }
      }
    }
  } 
}
