@import '../../../sass/abstracts/mixins';
@import '../../../sass/abstracts/variables';

.section-construcao{
  margin-top: 100px;

  @include sectionSetup();
  gap: 80px;

  @media (max-width: $max-width-mobile-sm) {
    flex-direction: column;
    gap: 10px;
  }

  &__info{
    width: 325px;

    h1{
      @include sectionPrimaryText();
    }
    p{
      @include sectionSecondaryText();
      text-align: left;
    }
  }
  img {
    width: 445px;
    height: 365px;

    @media (max-width: $max-width-mobile-sm) {
      width: 335px;
      height: 275px;
    }

  }
  
}
