@import '../abstracts/variables';

$max-width-board: 186px;

@mixin flex-center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@mixin flex-center-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
@mixin subtitle-text {
  font-size: 14px;
  color: $color-hard-gray;
}

.numbers-section {
  padding-top: 60px;
  padding-bottom: 80px;
}

.title-board {
  @include flex-center-column;
  @media (max-width: $max-width-mobile){
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 326px;
    max-height: 556px;
    margin: 30px auto 0px auto;
  }
  &__title {
    font-weight: 600;
    font-size: 26px;
  }
  &__subtitle {
    @include subtitle-text;
  }
}

.number-board {
  @include flex-center-column;
  @media (max-width: $max-width-mobile) {
    @include flex-center-column;
    max-width: 326px;
    max-height: 556px;
  }

  @media (min-width: $max-width-mobile) {
    @include flex-center-row;
    max-width: 876px;
    max-height: $max-width-board;
  }

  margin: 30px auto 0px auto;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  &__element {
    max-width: 100%;

    &__text {
      @include flex-center-column;
      margin: 35px;
      text-align: center;
      max-width: 100%;

      &_title {
        font-size: 40px;
        color: $color-primary-tint;
      }
      &_subtitle {
        @include subtitle-text;
      }
    }
  }
}
.hr-solid {
  @media (max-width: $max-width-mobile) {
    width: 100%;
    border-top: 1px solid $color-light-gray;
  }
  @media (min-width: $max-width-mobile) {
    display: none;
  }
}

.vl-solid {
  @media (max-width: $max-width-mobile) {
    display: none;
  }
  @media (min-width: $max-width-mobile) {
    border-left: 1px solid $color-light-gray;
    height: $max-width-board;
  }
}
