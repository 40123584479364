@import '../../../sass/abstracts/variables';

.card-informacao{
  width: 276px;
  height: 256px;
  padding: 25px 24px;
  border: 1.5px solid $color-light-gray;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: $color-white;

  @media (max-width: $max-width-mobile) {
    width: 100%;
    margin-top: 24px;
  }

  img{
    width: 24px;
    height: 24px;
  }


  h1{
    font-size: 20px;
    color: $color-primary-tint;
    font-weight: 600;
    margin-top: 16.45px;
    line-height: 110%;
  }

  p{
    font-size: 14px;
    color: $color-hard-gray;
    margin-top: 16px;
  }
}
