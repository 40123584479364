/** Website colors */
$color-primary: #2047ae;
$color-primary-hover: #0d359e;

$color-primary-tint: #4676fa;
$color-primary-tint-hover: #618bff;
$color-primary-tint-background: #e9effc;

$color-primary-shade: #092061;

$color-secondary-tint: #c4ac69;

$color-white: #fff;
$color-dark-black: #000;
$color-black: #2f3139;
$color-black-darker: #333333;

$color-light-gray: #d9dbe3;
$color-hard-gray: #75777f;
$color-gray: #F0F2F5;
$color-gray-secondary: #F6F8FC;

$color-disabled: #e2e7ee;

/** Website sizes */
$max-width-mobile-sm: 420px;
$max-width-mobile: 576px;
$max-width-medium: 768px;

$max-width-large: 900px;

/** Sections variables */
$section-primary-text-size: 26px;
$section-secondary-text-size: 14px;
