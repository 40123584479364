@import '../../../sass/abstracts/mixins';
@import '../../../sass/abstracts/variables';

.section-visao-gerencial{
  @include sectionSetup();
  gap: 80px;
  margin-top: 100px;
  &__info{
    margin-top: 50px;
    width: 340px;
    h1{
      @include sectionPrimaryText();
      @media (max-width: $max-width-mobile-sm) {
        margin-bottom: 15px;
      }
    }

    .bullet-list {
      @media (max-width: $max-width-mobile-sm) {
        display: none;
      }

      &__mobile{
        @media (max-width: $max-width-mobile-sm) {
          display: block;
        }
      }
    }

  }

  .img-desktop {
    width: 435px;
    height: 380px;
    @media (max-width: $max-width-mobile-sm) {
      display: none;
    }
  }

  .img-mobile{
    @media (max-width: $max-width-mobile-sm) {
      width: 330px;
      height: 290px;
    }
    @media (min-width: $max-width-mobile-sm) {
      display: none;
    }
  }
}
