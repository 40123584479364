.section-body {
  width: 880px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media(max-width: 900px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }  
}

.section-about {
  @include sectionSetup;

  padding-top: 135px;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  img {
    width: 350px;

    @media(max-width: $max-width-mobile) {
      width: 100%;
    }
  }
  
  &__primary-text {
    @include sectionPrimaryText;
  }

  &__secondary-text {
    @include sectionSecondaryText;
  }

  &__text {
    width: 350px;
    margin: 0px 194px 0px 0px;

    @media (max-width: 900px) {
      margin: 0px 0px 45px 0px;
    }

    @media(max-width: $max-width-mobile) {
      width: 100%;
    }
  }
}

.section-info {
  @include sectionSetup;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  @media (max-width: $max-width-mobile) {
    position: relative;
  }

  .section-body {
    align-items: center;
  }

  &__text-container {
    width: 260px;
    display: flex;
    flex-direction: column;

    margin-right: 60px;

    @media (max-width: 900px) {
      width: 350px;
      margin-bottom: 32px;
      margin-right: 0px;
    }

    @media (max-width: $max-width-mobile) {
      width: 100%;
    }
  }

  &__text {
    width: 100%;
  }

  &__action-button {
    &--mobile {
      display: none;
    }

    @media(max-width: $max-width-mobile) {
      display: none;
      &--mobile {
        display: inline-block;
        width: 100%;
      }
    }
  }

  &__primary-text {
    @include sectionPrimaryText;
  }

  &__secondary-text {
    @include sectionSecondaryText;

    @media (max-width: $max-width-mobile) {
      margin-bottom: 0px;
    }
  }

  &__info-list {
    margin-left: 60px;
    width: 280px;

    @media (max-width: 900px) {
      margin-left: 0;
    }

    @media (max-width: $max-width-medium)  {
      display: none;
    }
  }
  &__info-list-mobile {
    @media (max-width: $max-width-medium)  {
      display: flex;
      width: 80%;
    }
  }
}

.section-3 {
  @include sectionSetup;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  &__img {
    order: 1;
    width: 430px;
    height: 400px;

    margin-right: 100px;

    @media (max-width: 900px) {
      margin-right: 0px;
      width: 342px;
      height: 300px;

      order: 2;
    }

    @media (max-width: $max-width-mobile-sm) {
      width: 100%;
    }
  }

  &__action-button {
    &--mobile {
      display: none;
    }
    
    @media (max-width: 900px) {
      display: none;
      
      &--mobile {
        order: 3;
        display: inline-block;
        margin-top: 24px;

        width: 350px;
      }
    }

    @media (max-width: $max-width-mobile) {
      &--mobile {
        width: 100%;
      }
    }
  }

  &__text {
    order: 2;
    width: 350px;

    @media (max-width: 900px) {
      margin-bottom: 0px;
      order: 1;
    }

    @media (max-width: $max-width-mobile) {
      width: 100%;
    }
  }

  &__primary-text {
    @include sectionPrimaryText;
  }

  &__secondary-text {
    @include sectionSecondaryText;
  }
}

.section-4 {
  @include sectionSetup;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  &__img {
    order: 1;
    width: 207px;
    height: 442px;

    margin-right: 125px;

    @media (max-width: 900px) {
      margin-right: 0px;
    }

    @media (max-width: $max-width-mobile) {
      order: 2;

      transform: rotate(-13.65deg);
    }
  }

  &__text {
    order: 2;
    width: 364px;

    @media (max-width: $max-width-mobile) {
      width: 100%;
      margin-bottom: 25px;

      order: 1;
    }
    
    &__info {
      width: 100%;
      margin-bottom: 32px;

      &--mobile {
        display: none;
        text-align: center;
      }

      @media (max-width: $max-width-mobile) {
        display: none;
        
        &--mobile {
          display: inline-block;
          order: 4;
        }
      }
    }
  }

  &__primary-text {
    @include sectionPrimaryText;
  }

  &__secondary-text {
    @include sectionSecondaryText;
    width: 325px;

    &--mobile {
      display: none;
    }

    @media (max-width: $max-width-mobile) {
      display: none;

      &--mobile {
        display: inline-block;
        width: 100%;

        order: 3;
      }
    }
  }

  &__action-button {
    &--mobile {
      display: none;
    }

    @media(max-width: 900px) {
      display: none;
      &--mobile {
        display: inline-block;
        width: 350px;

        order: 5;
      }
    }

    @media (max-width: $max-width-mobile) {
      &--mobile {
        width: 100%;
      }
    }
  }
}

.swiper-pagination-bullet {
  background-color: $color-primary-tint !important;
}

.section-comentarios{
  width: 600px;
  height: 400px;

  @media (min-width: $max-width-mobile){
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
  } 

  @media (max-width: $max-width-mobile){
    display: none;
  }

  &_titulo{
    width: 80%;
    h2 {
      font-size: 26px;
      font-weight: 600;
      line-height: 120%;
      text-align: center;
    }
    
  }
}

.section-comentarios-mobile{
  @media (max-width: $max-width-mobile){
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 50px;

    &_container-swiper{
      width: 100%;
      height: 500px;
      padding: 0 25px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      //align-items: center;
      margin-top: 32px;
    }

    &_titulo{
      font-size: 26px;
      font-weight: 600;
      line-height: 120%;
      text-align: center;
      width: 90%;
    }

    &__swiper{
      display: flex;
      width: 290px;
      height: 500px;
      margin-top: 0px;
      z-index: 0;
    }

    &__swiper-slide {
      width: 261px;
      height: 100%;
    
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;

      &_card{
        width: 100%;
      }
    }
  }
  
  @media (min-width: $max-width-mobile) {
    display: none;
  }

}

.section-5 {
  width: 100%;
  padding: 80px 100px;

  background: rgba($color-primary, 0.04);

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 1100px) {
    flex-direction: column;
  }

  @media(max-width: $max-width-mobile) {
    padding: 80px 24px;
  }

  .list {
    margin: 30px 0px;
  }

  &__text {
    width: 432px;
    margin-right: 93px;

    @media(max-width: $max-width-medium) {
      width: 327px;
      margin-right: 0px;
    }

    @media(max-width: $max-width-mobile) {
      width: 100%;
    }
  }

  &__overtitle {
    font-size: 16px;
    font-weight: 700;
  }

  &__primary-text {
    @include sectionPrimaryText;

    &-primary {
      font-size: 26px;
      font-weight: 600;
      color: $color-primary;
    }
  }

  img {
    width: 520px;
    height: 400px;

    @media(max-width: 1100px) {
      margin-top: 30px;
    }

    @media(max-width: $max-width-medium) {
      width: 327px;
      height: 252px;
      margin-top: 0px;
    }

    @media (max-width: $max-width-mobile) {
      width: 100%;
    }
  }

  &__action-button {
    @media(max-width: $max-width-medium) {
      display: none;
    }

    &--mobile {
      display: none;
      width: 327px;
      margin-top: 30px;

      @media(max-width: $max-width-medium) {
        display: block;
      }

      @media (max-width: $max-width-mobile) {
        width: 100%;
      }
    }
  }
}

.section-numbers {
  padding: 25px 100px;
  background: $color-primary;

  display: flex;
  justify-content: space-between;
  width: 100%;

  @media(max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }

  @media(max-width: $max-width-mobile) {
    padding: 24px;
  }

  .numbers-info {
    display: flex;
    align-items: center;

    @media(max-width: 1000px) {
      &:not(:last-child) {
        margin-bottom: 32px;
      }

      flex-direction: column;
    }

    &__number {
      font-size: 32px;
      font-weight: 700;
      color: $color-white;

      margin-right: 16px;

      @media (max-width: 1000px) {
        margin-right: 0px;
      }
    }

    &__info {
      width: 190px;
      font-size: 14px;
      font-weight: 400;
      color: $color-white;

      @media(max-width: 1000px) {
        width: 100%;
        padding: 0px 16px;
        text-align: center;
      }
    }

    &:last-child {
      .numbers-info__info {
        width: 170px;
      }
    }
  }
}

.footer {
  padding: 25px 112px;
  background: $color-black;
  width: 100%;
  justify-content: space-between;

  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    align-items: center;
  }

  @media (max-width: $max-width-mobile) {
    padding: 24px;
  }

  &__logo {
    width: 125px;
    height: 32px;
  }

  &__links {
    display: flex;
    align-items: flex-start;
    width: 100%;

    margin: 35px 0px;
    

    @media (max-width: 1000px) {
      margin: 40px 0px;
      border-bottom: none;

      flex-direction: column;
      align-items: center;
    }

    &__download{
      display: flex;
      flex-direction: column;
      width: 35%;
      
      @media (max-width:1000px) {
        align-items: center;
        margin-bottom: 30px;
      }
    }

    &__itens{
      display: flex;
      flex-direction: row;
      width: 65%;
      justify-content: center;

      @media (max-width:1000px) {
        flex-direction: column;
      }
    }

    &__itens-end{
      display: flex;
      flex-direction: row;
      width: 65%;
      justify-content: flex-end;

      @media (max-width:1000px) {
        flex-direction: column;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-right: 120px;
      }
      
      @media (max-width: 1000px) {
        &:not(:last-child) {
          margin-right: 0px;
          margin-bottom: 40px;
        }

        align-items: center;
      }

      &__column {
        margin-top: 10px;
        
        &:last-child {
          margin-top: 0
        }
      }

      &__title {
        font-weight: 700;
        font-size: 16px;
        color: $color-white;
        margin-bottom: 15px;
      }
      &__subtitle {
        font-weight: 700;
        font-size: 16px;
        color: $color-white;
      }

      &__text{
        font-size: 14px;
        font-weight: 400;
        color: $color-white;

        text-decoration: none;
        margin-bottom:15px;
      }

      &__link {
        font-size: 14px;
        font-weight: 400;
        color: $color-white;

        text-decoration: none;
        margin-bottom:15px;

        &:hover {
          text-decoration: underline;
        }
      }
      
      .modal-empresarial-trigger {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;
        text-align: inherit;
        font-style: inherit;
        font-variant-ligatures: inherit;
        font-variant-caps: inherit;
        font-variant-numeric: inherit;
        font-variant-east-asian: inherit;
        font-weight: inherit;
        font-stretch: inherit;
        line-height: inherit;
        font-family: inherit;      
    }
    }
  }

  &__copyright {
    display: inline-block;
    color: $color-white;
    font-size: 12px;
    font-weight: 400;
    margin-top: 20px;

    @media(max-width: 1200px) {
      display: none;
    }
  }

  &__medias {
    display: flex;
    align-items: center;

    position: relative;

    &__logos {
      position: absolute;
      top: 50%;
      left: 50%;

      display: flex;

      transform: translate(-50%,-50%);

      &__logo {
        display: inline-block;
  
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
  
        &:hover {
          background: rgba(255, 255, 255, 0.32);;
          
        }
  
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
}

.contato-form {
  height: 100%;
  &__header {
    width: 100%;
    text-align: end;
    padding: 30px;

    &__close {
      width: 16px;
      height: 16px;

      cursor: pointer;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;

    &__title {
      font-weight: 600;
      font-size: 26px;
      margin-bottom: 16px;
    }

    &__subtitle {
      font-size: 14px;
      font-weight: 400;
      color: #75777F;
    }

    &__form {
      &__actions {
        display: flex;
        justify-content: center;
        padding: 0 50px;

        .button {
          width: 100%;
        }

        @media(max-width: $max-width-mobile) {
          padding: 0px 0px 20px 0px;
        }
      }
    }

    padding: 0px 70px;
  }
  
  #feedbackSucessoContainer {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.button-previous, .button-next{
  background-color: #FFF;
  border: none;
  margin-bottom: 90px;
}
