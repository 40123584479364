@import '../../../sass/abstracts/mixins';
@import '../../../sass/abstracts/variables';

.section-video {
  @include sectionSetup;

  flex-direction: column;
  gap: 16px;

  padding-top: 80px;

  @media (max-width: $max-width-mobile) {
    padding-top: 24px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    margin: 0 auto;
  }
}

.title-board {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.title-board__title {
  max-width: 380px;
  text-align: center;
}

.medflow-demonstracao__video {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 491px;
  height: 274px;
}

.medflow-demonstracao__integracoes {
  display: flex;
  width: 479px;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .medflow-demonstracao__integracoes__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    .medflow-demonstracao__integracoes__title {
      color: #75777F;
      text-align: center;
  
      /* corpo 2 */
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .medflow-demonstracao__integracoes__list {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 8px;

      .medflow-demonstracao__integracoes__list__item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 12px;

        height: 30px;
        border-radius: 15px;
        border: 2px solid #D9DBE3;

        img {
          height: 100%;
          width: 100%;

          flex-shrink: 0;
          object-fit: contain;
        }
      }
    }
  }
  
  .medflow-demonstracao__integracoes__button {
    display: flex;
    width: 220px;
    height: 40px;
    padding: 14px 54px;
    justify-content: center;
    align-items: center;
  }
}