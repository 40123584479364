.link {
  color: $color-black-darker;
  font-size: 14px;
  font-weight: 500;

  text-decoration: none;

  &__navbar {
    cursor: pointer;
    color: $color-black-darker;
  }

  &--primary-link {
    color: $color-primary-tint;
  }

  &__navbar:hover {
    color: $color-primary-tint;
  }
}
