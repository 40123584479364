@keyframes openModal {
  from {
    right: -600px;
  }

  to {
    right: 0px;
  }
}

@keyframes closeModal {
  from {
    right: 0px;
  }

  to {
    right: -600px;
  }
}


@keyframes showModal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.modal{
  display: none;
  width: 100%;
  height: 100vh;
  z-index: 1;
  opacity: 0;

  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(#2F3139, 0.3);
  // opacity: 0.3;

  &__body {
    position: absolute;
    overflow: auto;

    top: 0;
    right: -600px;

    width: 600px;
    height: 100%;

    &__centered {
      width: 1024px;
      height: 700px;

      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);

      border-radius: 16px;
    }

    @media(max-width: 600px) {
      right: 0;
      width: 100%;
    }

    background: $color-white;

    &--open {
      animation-name: openModal;
      animation-duration: .25s;
      animation-fill-mode: forwards;
    }

    &--close {
      animation-name: closeModal;
      animation-duration: .25s;
      animation-fill-mode: forwards;
    }
  }

  &--open {
    display: flex;

    animation-name: showModal;
    animation-duration: .4s;
    animation-fill-mode: forwards;
  }
}

.modal-confirmacao {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  &__header {
    width: 100%;
    text-align: end;
    padding: 30px;

    &__close {
      width: 16px;
      height: 16px;

      cursor: pointer;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 0 73px;

    &__title {
      font-weight: 600;
      font-size: 26px;
      margin-bottom: 16px;
    }

    &__subtitle {
      font-size: 14px;
      font-weight: 400;
      color: $color-hard-gray;
      margin-bottom: 31px;
    }
    
    button{
      width: 142px;
      height: 40px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 600;
      color: $color-white;
      cursor: pointer;
      background-color: $color-primary-tint;
      
      &:hover{
        background-color: $color-primary-tint-hover;
      }
    }
  }
  
  &__spacer {
    height: 85px;
  }
}

.modal-execucao {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__header {
    width: 100%;
    text-align: end;
    padding: 20px;

    background-color: #F0F2F5;

    &__close {
      position: absolute;
      width: 16px;
      height: 16px;

      top: 12px;
      right: 16px;

      cursor: pointer;
    }
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
      font-weight: 600;
      font-size: 26px;
      margin-bottom: 16px;
    }

    &__subtitle {
      font-size: 14px;
      font-weight: 400;
      color: $color-hard-gray;
      margin-bottom: 31px;
    }
    
    &__loading {
      display: flex;
      justify-content: center;

      svg {
        width: 32px;
        height: 32px;
        animation: rotate 600ms linear infinite;
        display: block;
      }
    }

    button {
      width: 142px;
      height: 40px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 600;
      color: $color-white;
      cursor: pointer;
      background-color: $color-primary-tint;

      &:hover {
        background-color: $color-primary-tint-hover;
      }
    }
  }

  &__spacer {
    height: 85px;
  }
}