@import '../../../sass/abstracts/variables';
.main-banner-root {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-banner {
  display: flex;
  flex-direction: column;
  width: 1054px;
  margin: 0 auto 180px auto;
  position: relative;
  top: 60px;
  padding-top: 60px;

  @media (max-width: 1080px) {
    width: 100%;
    padding: 0;
    top: 90px;
  }
  
  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 1080px) {
      width: 100%;
      margin-bottom: 100px;
      flex-direction: column;
    }
  }

  &__icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 37px;
    align-items: center;

    @media (max-width: 1080px) {
      flex-direction: column;
      align-items: center;
    }

    .iconParceirosMedflow{
      @media (max-width: 1080px) {
        height: 21px;
      }
    }

    .iconEurofarma{
      @media (max-width: 1080px) {
        height: 29px;
      }
    }

    .iconHapvida{
      @media (max-width: 1080px) {
        height: 26px;
      }
    }

    .iconLivSaude{
      @media (max-width: 1080px) {
        height: 18px;
      }
    }

    .iconUnimed{
      height: 30.12px;
      width: 107px;
    }
    
    .iconAthena{
      height: 41px;
      width: 107px;
    }

    .iconLeveSaude{
      @media (max-width: 1080px) {
        height: 30px;
      }
    }
    img {
      display: block;
      @media (max-width: 1080px) {
        width: 50%;
        margin: 8px 0px;
      }
    }
  }
}
.banner {
  &__title {
    font-weight: 700;
    font-size: 60px;
    line-height: 110%;
    color: $color-black;
    @media (max-width:1080px) {
      font-weight: 700;
      font-size: 38px;
      line-height: 110%;
      margin-top: 24px;
      margin-bottom: 16px;
      padding: 0 24px;
    }
  }
  &__img {
    display: block;
    width: 485px;
    @media (max-width:1080px) {
      width: 70%;
      order: -1;
    }
    @media (max-width:$max-width-mobile) {
      width: 100%;
      padding: 0 24px;
      order: -1;
    }
  }
  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-black;
    margin: 16px 0px;
    padding: 16px 0px;
    @media (max-width: 1080px) {
      padding: 0 24px;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: row;

    @media (max-width: 1080px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__button {
    cursor: pointer;
    text-decoration: none;
    padding: 11px 21px;
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 1080px) {
      width: 90%;
      margin: 8px 0px;
    }

    &__primary {
      background-color: $color-primary-tint;
      color: $color-white;
      margin-left: 16px;
      &:hover {
        background-color: $color-primary-tint-hover;
      }
    }

    &__secondary {
      border: 2px solid $color-primary;
      color: $color-primary;      
      @media (max-width: 1080px) {
        margin-left: 0px;
      }
      &:hover {
        background-color: $color-primary-tint-background;
      }
    }
  }
}
