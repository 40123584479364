.bullet-list {
  @include sectionSecondaryText;
  font-weight: 300;
  font-size: 12px;
  list-style: none;

  list-style-image: url('../../assets/img/bullet-list.svg');
  vertical-align: middle;
  margin-left: 17px;
  li {
    vertical-align: middle;
    padding-left: 10px;
    margin-bottom: 16px;
  }
  li span {
    display: inline-block;
    vertical-align: middle;
  }

  &__mobile {
    @extend .bullet-list;
    display: none;
    @media (max-width: $max-width-mobile) {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 12px;
      margin-top: 12px;
    }
  }
}
