@import '../../../sass/abstracts/variables';

.tecnologia-banner{  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  top: 60px;
  margin-bottom: 60px;
  @media (max-width: $max-width-mobile){
    flex-direction: column;
    align-items: center;
    // margin-bottom: 30px;
    margin-top: 50px;
  }

  &__container{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width:$max-width-mobile-sm) {
      display: none;
    }

    &__text{
      width: 523px;
    }

    &__mobile{
      
      @media (min-width:$max-width-mobile-sm) {
        display: none;
      }
      &__text{
        width: 100%;
      }
    }
  }

  &__img{
    @media (max-width: $max-width-mobile){
      display: none;
    }

    &__mobile{
      display: none;
      @media (max-width:$max-width-mobile){
        display: block;
        width: 95%;
      }
    }
  }
}
