@import '../../../sass/abstracts/variables';

.container {
  width: 100%;
  height: 128px;
  padding: 0 8.88% 0 8.75%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 80px 0 87px 0;

  @media (max-width: $max-width-mobile) {
    height: 318px;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
  }

  .texts {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: $max-width-mobile) {
      width: 327px;
    }

    h1{
      font-size: 26px;
      font-weight: 600;
      color: $color-black;

      @media (max-width: $max-width-mobile) {
        margin-bottom: 16px;
      }
    }

    span, p{
      font-size: 16px;
      font-weight: 400;
    }

    span{
      color: $color-hard-gray;
    }

    p{
      color: $color-black;
    }
  }

  .box-actions {
    width: 50%;
    height: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1%;

    @media (max-width: $max-width-mobile) {
      width: 327px;
      margin-top: 24px;
      margin-left: 0;
    }

    .card-actions {
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 4%;
      padding-right: 4.7%;
      border: 1.5px solid $color-light-gray;
      border-radius: 10px;
      cursor: pointer;

      .icon-and-text{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;

        .whatsapp{
          margin-right: 16px;
        }

        .ask{
          margin-right: 13px;
        }
      }

      &:hover{
        background-color: $color-gray;
      }
    }
    
    .card-actions:nth-child(2){
      margin-top: 8px;
    }
  }
}
