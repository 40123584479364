.section-body-landing {
  width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 99px;
  @media (max-width: 1108px) {
    margin: auto;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    left: 0;
    width: 100%;
  }
}
