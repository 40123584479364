@import '../../sass/abstracts/variables';

.container-not-found{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: $max-width-mobile) {
    padding: 0 24px 0 25px;
    box-sizing: border-box;
    align-items: flex-start;
    // justify-content: flex-start;
  }

  .box-error{
    width: 420px;
    height: 298px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $max-width-mobile) {
      width: 326px;
      height: 325px;
      margin-top: 148px;
    }

    img{
      width: 357.5px;

      @media (max-width: $max-width-mobile) {
        width: 326px;
      }
    }

    p{
      font-size: 16px;
      font-weight: 400;
      color: $color-hard-gray;
      margin-top: 24px;
      text-align: center;
    }

    .redirect{
      width: 117px;
      text-align: center;
      margin-top: 24px;
      text-decoration: none;

      span{
        color: $color-primary-tint;
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;

        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}
