.products__selection {
  width: 693px;
  height: 395px;

  position: absolute;
  top: 60px;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  background: $color-white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 20px 20px;

  @media (max-width: 1080px) {
    right: 20px;
  }
  
  &-b2c{
    left: 16px;
  }

  &-content {
    width: 100%;
    height: 100%;
    padding: 28px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__primary-text {
    font-weight: 600;
    font-size: 26px;
    color: $color-white;
  }

  &__secondary-text {
    font-size: 14px;
    color: $color-white;
    line-height: 21px;
    font-weight: 400;
  }

  &-button-light {
    width: 149px;
    height: 32px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $color-primary-tint;
    cursor: pointer;
    text-decoration: none;
    color: $color-white;

    &:hover {
      background-color: $color-primary-tint-hover;
    }
  }

  &-button-primary {
    width: 149px;
    height: 32px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $color-primary-tint;
    cursor: pointer;
    text-decoration: none;
    color: $color-white;

    &:hover {
      background-color: $color-primary-tint-hover;
    }

    &__enterprise{
      @extend .products__selection-button-primary;
      background-color: $color-primary;

      &:hover {
        background-color: $color-primary-hover;
      }
    }
  }

  

  .options {
    width: 661px;
    height: 174px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    border-radius: 20px;
  }

  .product-1 {
    background-image: url('../../assets/img/imagem-topo-site-sem-brinco 1.png');
  }

  .product-2 {
    background-image: url('../../assets/img/arabian-doctor.png');
  }
}
